import React from 'react';
import OwlCarousel from '../../features/owl-carousel';
import ReviewCard from './reviewCard';
import { reviewSlider } from '../../../utils/data/slider';
import { reviews } from '../../../utils/data/landing';

function ReviewsSection() {
  const reviewsList = reviews;

  return (
    <div className="review-container">
      <div className="review-box">
        <h2 className="review-title font5">
          Trusted by Thousands of Happy Customers
        </h2>
        <span className="review-text font4">
          We work hard to earn genuine 5-star reviews from quality customers
          like you 🙂
        </span>
      </div>
      <OwlCarousel className="review-cards" options={reviewSlider}>
        {!!reviewsList.length &&
          reviewsList.map((item, index) => (
            <ReviewCard key={index} review={item} />
          ))}
      </OwlCarousel>
      {/* <ALink href={``} className="ghost-btn font4">
        Check all reviews
      </ALink> */}
    </div>
  );
}

export default React.memo(ReviewsSection);
